import React from 'react';
import { Modal, List, Card } from 'antd';
import CompanyDetailsPage from '../LeadsPage/CompanyDetailsPage';  // Assuming this import is needed
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';  // Assuming this import is needed

const InboxModal = ({ visible, onClose, selectedThread }) => {
  const formatMessageBody = (body) => {
    // Replace double line breaks (\n\n) with paragraph tags
    const paragraphs = body.split("\n\n").map(
      (paragraph) => `<p>${paragraph.replace(/\n/g, '<br />')}</p>`
    );
  
    // Combine into a single HTML string
    const htmlContent = paragraphs.join('');
  
    return { __html: htmlContent };
  };  

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={800}
      title="Inbox Details"
    >
      {selectedThread ? (
        <>
          <List
            itemLayout="vertical"
            dataSource={
              selectedThread.email_detail?.messages
                ?.sort((a, b) => new Date(a.sent_at) - new Date(b.sent_at)) || []
            }
            renderItem={(message) => (
              <List.Item key={message.message_id}>
                <Card
                  className={`message-box ${message.message_type !== 0 ? 'user-message' : 'contact-message'}`}
                  style={{
                    alignSelf: message.message_type !== 0 ? 'flex-start' : 'flex-end',
                    backgroundColor: message.message_type !== 0 ? '#f5f5f5' : '#e0e0e0',
                    marginLeft: message.message_type !== 0 ? 0 : 'auto',
                    marginRight: message.message_type !== 0 ? 'auto' : 0,
                    maxWidth: '100%',
                    padding: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <div className="message-sender">{message.sender}</div>
                  <div
                    className="message-body"
                    dangerouslySetInnerHTML={formatMessageBody(message.body)}
                  ></div>
                  <div className="message-date">{new Date(message.sent_at).toLocaleString()}</div>
                </Card>
              </List.Item>
            )}
          />
        </>
      ) : (
        <div>Select a thread to view messages</div>
      )}
    </Modal>
  );
};

export default InboxModal;

