import React, { useEffect, useState } from 'react';
import { Layout, Popover, Row, Col, Card, Statistic, Table, Avatar, Tag, Space, Image, Button, Timeline, Modal,Typography } from 'antd';
import { MailOutlined, SyncOutlined, ClockCircleOutlined } from '@ant-design/icons';
import useDocumentPageHook from '../DocumentPage/DocumentPageHook';
import DetailsDrawer from '../LeadsPage/DetailsDrawer';
import InboxModal from './InboxModal';

const { Content } = Layout;
const { Paragraph } = Typography;


const EmailState = {
  0: 'Default',
  1: 'Sent',
  2: 'Followed Up',
  3: 'Responded',
  4: 'Cannot Delivered',
  5: 'Pending Approval',
  6: 'Notify User'
};

const InboxOverviewPage = () => {
  const { getReachOutDetails } = useDocumentPageHook();
  const [data, setData] = useState([]);
  const [stats, setStats] = useState({});
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [sequenceModalVisible, setSequenceModalVisible] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState([]);
  const [followedUpCount, setFollowedUpCount] = useState(0);

  const toggleDrawer = (record) => {
    setDrawerVisible(!drawerVisible);
    setSelectedLead(record);
  };

  const onDrawerClose = () => {
    setDrawerVisible(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const details = await getReachOutDetails('inbox');
      if (details) {
        setData(details.url_details);
        setStats(details.stats);
      }
    };
    fetchData();
  }, []);

  const calculateRates = () => {
    const totalEmailsSent = stats.total_emails_sent || 1; // Avoid division by zero
    return {
      repliedRate: ((stats.total_emails_replied / totalEmailsSent) * 100).toFixed(2),
      openRate: ((stats.total_emails_opened / totalEmailsSent) * 100).toFixed(2),
    };
  };

  // Function to render the popover content
  const renderSequenceTimeline = (sequence, followedUpCount) => (
    <Timeline mode="left" style={{ padding: '5px 0', marginBottom:0 }}>
      {sequence?.map((step, index) => (
        <Timeline.Item
          color={index <= followedUpCount ? 'green' : 'gray'}
          key={index}
          dot={
            step.stepType === 'email' ? (
              <MailOutlined style={{ fontSize: '16px' }} />
            ) : (
              <SyncOutlined style={{ fontSize: '16px' }} />
            )
          }
        >
          <Paragraph style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
            <strong>{step.stepType === 'email' ? 'Initial Email Sent' : `Follow-up after ${step.days} day${step.days > 1 ? 's' : ''}`}</strong>
            {index <= followedUpCount && (
              <span style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', color: 'green' }}>
                <ClockCircleOutlined style={{ marginRight: '5px' }} />
                Completed
              </span>
            )}
          </Paragraph>
        </Timeline.Item>
      ))}
    </Timeline>
  );


  const { repliedRate, openRate } = calculateRates();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Space>
          <Image
            width={35}
            height={35}
            src={record.contact?.logo_url || '/person_icon.png'}
            style={{ borderRadius: '50%' }}
            alt="profile"
          />
          <a
            style={{ color: '#1890ff', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => toggleDrawer(record)}
          >
            {record.contact?.name || 'Unknown'}
          </a>
        </Space>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'email_detail',
      key: 'status',
      render: (emailDetail) => {
        const statusText = EmailState[emailDetail.state];
        return (
          <>
            <Tag color={emailDetail.state === 3 ? 'green' : 'blue'}>{statusText}</Tag>
            {emailDetail.open_count > 0 && (
              <Tag color="volcano">Opened: {emailDetail.open_count}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: 'Sequence',
      dataIndex: 'email_detail',
      key: 'sequence',
      render: (emailDetail) => (
        <Popover
          content={renderSequenceTimeline(emailDetail.sequences, emailDetail.followed_up_count)}
          trigger="hover"
        >
          <Button>View Sequence</Button>
        </Popover>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button
          onClick={() => {
            setSelectedLead(record);
            setDetailsModalVisible(true);
          }}
        >
          View Inbox
        </Button>
      ),
    },
  ];

  const tableData = data.map((item, index) => ({
    key: index,
    ...item,
    contact: item.company_data?.contact_people[0],
  }));

  return (
    <Layout style={{ padding: '20px' }}>
      <Content>
        {/* Statistics Row */}
        <Row gutter={16} style={{ marginBottom: 20 }}>
          <Col span={8}>
            <Card>
              <Statistic title="Total Emails Sent" value={stats.total_emails_sent} prefix={<MailOutlined />} />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic title="Replied Rate" value={`${repliedRate}%`} prefix={<MailOutlined />} />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic title="Open Rate" value={`${openRate}%`} prefix={<MailOutlined />} />
            </Card>
          </Col>
        </Row>

        {/* Table of People */}
        <Table columns={columns} dataSource={tableData} />

        <DetailsDrawer visible={drawerVisible} onClose={onDrawerClose} record={selectedLead} />

        <InboxModal
          visible={detailsModalVisible}
          onClose={() => setDetailsModalVisible(false)}
          selectedThread={selectedLead}
        />
      </Content>
    </Layout>
  );
};

export default InboxOverviewPage;
