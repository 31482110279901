import React, { useState, useRef, useEffect } from 'react';
import { Button, Space, Tag, Image, Collapse, message, Modal, Row, Col, Divider, Typography, Tooltip, Drawer } from 'antd';
import { InfoCircleOutlined, LinkedinOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import CompanyDetailsPage from './CompanyDetailsPage';

const { Title, Text } = Typography;
const { Panel } = Collapse;

const PeopleDetailsDrawer = ({ visible, onClose, record }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);

  if (!record) return;
  console.log(record)

  const lead = record;
  const contactPerson = lead?.contact_people ? lead.contact_people[0] : {};
  //const contactPerson = record

  const formatMessageBody = (body) => {
    return { __html: body?.replace(/\n/g, '<br />') };
  };

  const handleDetailVisible = (value) => {
    setDetailsModalVisible(value);
  };

  const copyToClipboard = () => {
    const textToCopy = lead.email.message?.replace(/\\n/g, '\n');
    navigator.clipboard.writeText(textToCopy).then(() => {
      message.success('Message copied to clipboard');
    }).catch(() => {
      message.error('Failed to copy message');
    });
  };
  const truncateContent = (content, maxLength = 35) => {
    if (!content) return '';
    return content.length > maxLength ? content.slice(0, maxLength) + '...' : content;
  };    

  const renderInsights = (insights, tag) => {
    const filteredQuestions = insights?.filter((question) => (question.tag || 'PREFERRED') === tag);
  
    return filteredQuestions?.map((question, index) => (
      <div key={index} style={{ marginBottom: 16 }}>
        <Text>Q: {question.question}</Text>
        <div>
          <Text strong>
            A: {Array.isArray(question.answer) 
              ? question.answer.join('\n') // Join array elements with new lines
              : question.answer}. 
          </Text>
          <Tooltip
            title={
              <div dangerouslySetInnerHTML={{ __html: question?.additional_insights?.join('<br /><br>') }} />
            }
          >
            <Button shape="circle" icon={<InfoCircleOutlined />} style={{ marginLeft: 8 }} />
          </Tooltip>
        </div>
        <div>
          <Text>Note: {question.short_reply}</Text>             
        </div>
        {question.reference && question.reference.length > 0 && (
          <div style={{ marginTop: 8 }}>
            {question.reference.filter(ref => ref.startsWith('http') || ref.startsWith('https')).map((ref, idx) => (
              <Tag color="blue" key={idx}>
                <a href={ref} target="_blank" rel="noopener noreferrer">{truncateContent(ref)}</a>
              </Tag>
            ))}
          </div>
        )}
        <Divider />
      </div>
    ));
  };
  

  const renderSignals = (signals, color) => {
    return (
      <ul>
        {signals?.map((signal, index) => (
          <li key={index} style={{ color: color }}>
            {signal}
          </li>
        ))}
      </ul>
    );
  };  

  const InsightsSection = ({ title, tag, companyInsights, peopleInsights }) => {
    // Combine both company and people insights under the same tag
    const combinedInsights = [...(peopleInsights?.key_questions || [])];
  
    const renderedInsights = renderInsights(combinedInsights, tag)
    if (renderedInsights.length === 0) {
      return null;
    }    
  
    return (
      <>
        <Title level={4}>{title}</Title>
        <Divider solid style={{ marginBottom: 10, marginLeft: 0, marginTop: 0, marginRight: 0, borderColor: '#000000' }} />
        {renderedInsights}
      </>
    );
  };  

  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const DescriptionItem = ({ title, content }) => (
    <div>
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8, marginTop: 8 }}>
        <Text strong style={{ marginRight: 8 }}>{title}:</Text>     
    </div>
    <Space>
    {content}
    </Space>       
    </div>    
  );  

  return (
    <Drawer
      title="Details"
      placement="right"
      onClose={onClose}
      visible={visible}
      mask={false}
      width={400}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <div>
          <Row align="middle" justify="center" style={{ margin: 0, padding: 0 }}>
            <Col offset={0}>
              <Image
                width={35}
                height={35}
                src={contactPerson.logo_url || '/person_icon.png'}
                style={{ borderRadius: '50%' }}
                alt="logo"
              />
            </Col>
            <Col offset={0} style={{marginLeft: 10}}>
              <Title level={5} style={{ margin: 0 }}>
                {contactPerson?.name || 'N/A'}
              </Title>
            </Col>
          </Row>
          <Row style={{ margin: 0, padding: 0 }}>
            <Col span={14}>
                <DescriptionItem title="Title" content={contactPerson?.title || 'N/A'} />
            </Col>
            <Col span={10}>
                <DescriptionItem title="Linkedin" content={<a href={contactPerson?.url} target="_blank" rel="noopener noreferrer"><LinkedinOutlined style={{ fontSize: '25px', color: '#0077b5'}} /></a>} />
            </Col>            
          </Row>
          <Row style={{ margin: 0, padding: 0 }}>
            <Col span={14}>
                <DescriptionItem title="Email" content={contactPerson?.email_address || 'N/A'} />
            </Col>
            <Col span={10}>
                <DescriptionItem title="Phone" content={contactPerson?.phone_number || 'N/A'} />

            </Col>            
          </Row>
          <Row style={{ margin: 0, padding: 0 }}>
            <Col span={14}>
              <DescriptionItem title="Reachout" content={<Button type="primary" onClick={handleModalOpen}>Message Template</Button>} />
            </Col>        
          </Row>                  
        </div>
        <Divider style={{margin:5}} />

        <div>
          <InsightsSection title="Preferred Insights" tag="PREFERRED" companyInsights={lead.company_insights} peopleInsights={lead.people_insights} />
          <InsightsSection title="Filtered Insights" tag="FILTERED" companyInsights={lead.company_insights} peopleInsights={lead.people_insights} />
          <InsightsSection title="More Information" tag="MORE_INFO" companyInsights={lead.company_insights} peopleInsights={lead.people_insights} />          
        </div>
        <div>         
        </div>
      </Space>

      <Modal
        title="Email Message"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <div>
          <ReactMarkdown>{'**' + lead.email.title?.replace(/\\n/g, '  \n') + '**'}</ReactMarkdown>
          <div dangerouslySetInnerHTML={formatMessageBody(lead.email.message)}></div>
          <Button type="primary" onClick={copyToClipboard} style={{ marginTop: '16px' }}>
            Copy Message
          </Button>
        </div>
      </Modal>

    </Drawer>
  );
};

export default PeopleDetailsDrawer;
