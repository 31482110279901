import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Space, Tag, Image, Collapse, message, Modal, Row, Col, Divider, Typography, Tooltip, Drawer, Table } from 'antd';
import { InfoCircleOutlined, LinkedinOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import CompanyDetailsPage from './CompanyDetailsPage';

const { Title, Text } = Typography;
const { Panel } = Collapse;

const CompanyDetailsDrawer = ({ visible, onClose, record }) => {
    const navigate = useNavigate();

  if (!record) return;
  console.log(record)
  const lead = record.company_data;
  const contactPeople = lead?.contact_people || [];

  const DescriptionItem = ({ title, content }) => (
    <div>
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8, marginTop: 0 }}>
        <Text strong style={{ marginRight: 8 }}>{title}:</Text>     
    </div>
    <Space>
    {content}
    </Space>       
    </div>    
  ); 
  
  const columns = [
    {
      title: 'Contact',
      dataIndex: 'logo_url',
      key: 'logo_url',
      render: (text, record) => (
        <Space>
          <Image
            width={35}
            height={35}
            src={record.logo_url || '/person_icon.png'}
            style={{ borderRadius: '50%' }}
            alt="profile"
          />
          <span>{record.name || 'N/A'}</span>
        </Space>
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'LinkedIn',
      dataIndex: 'url',
      key: 'url',
      render: url => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <LinkedinOutlined style={{ fontSize: '20px', color: '#0077b5' }} />
        </a>
      )
    }
  ];  

  const logo = (
    <Row align="middle" justify="space-between" style={{ margin: 0, padding: 0 }}>
    <Col style={{marginLeft:0}}>
        <Image
        width={35}
        height={35}
        src={lead.company_logo_url || '/person_icon.png'}
        style={{ borderRadius: '50%' }}
        alt="logo"
        />
        <Title level={5} style={{ margin: '0 0 0 10px', display: 'inline-block' }}>
        {lead.company_name || 'N/A'}
        </Title>
    </Col>
    </Row>    
  )
  const goToDetailsPage = () => {
    navigate(`/campaign/leads/${record.url_id}`, { state: { lead: record.company_data } });
  }; 

  const truncateContent = (content, maxLength = 35) => {
    if (!content) return '';
    return content.length > maxLength ? content.slice(0, maxLength) + '...' : content;
  };    

  const renderInsights = (insights, tag) => {
    const filteredQuestions = insights?.filter((question) => (question.tag || 'PREFERRED') === tag || question.tag === 'MORE_INFO');
  
    return filteredQuestions?.map((question, index) => (
      <div key={index} style={{ marginBottom: '0px' }}>
        <Text>Q: {question.question}</Text>
        <div>
          <Text strong>
            A: {Array.isArray(question.answer) 
              ? question.answer.join('\n') // Join array elements with new lines
              : question.answer}. 
          </Text>
          <Tooltip
            title={
              <div dangerouslySetInnerHTML={{ __html: question?.additional_insights?.join('<br /><br>') }} />
            }
          >
            <Button shape="circle" icon={<InfoCircleOutlined />} style={{ marginLeft: 8 }} />
          </Tooltip>
        </div>
        <Divider style={{marginTop:10}} />
      </div>
    ));
  };

  
  const InsightsSection = ({ title, tag, companyInsights, peopleInsights }) => {
    // Combine both company and people insights under the same tag
    const combinedInsights = [...(companyInsights?.key_questions || [])];
  
    const renderedInsights = renderInsights(combinedInsights, tag)
    if (renderedInsights.length === 0) {
      return null;
    }    
  
    return (
      <>
        <Title level={4}>{title}</Title>
        <Divider solid style={{ marginBottom: 10, marginLeft: 0, marginTop: 0, marginRight: 0, borderColor: '#000000' }} />
        {renderedInsights}
      </>
    );
  };    

  return (
    <Drawer
      title={logo}
      placement="right"
      onClose={onClose}
      visible={visible}
      mask={false}
      width={400}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <div>
          <Row style={{ margin: 0, padding: 0 }}>
          <Col span={16}>
              <DescriptionItem title="Website" content={<a href={lead.company_website_url} target="_blank" rel="noopener noreferrer">{lead.company_website_url}</a>} />
            </Col>
            <Col span={8}>
              <DescriptionItem title="Linkedin" content={<a href={lead.company_linkedin_url} target="_blank" rel="noopener noreferrer"><LinkedinOutlined style={{ fontSize: '25px', color: '#0077b5' }} /></a>} />
            </Col>                                 
          </Row>        
        </div>
        <Divider style={{margin:5}} />

      </Space>
      <Row><Col span={24}><Button type='primary' style={{marginTop:'10px', width:'100%', marginBottom:'10px'}} onClick={goToDetailsPage}>View Full Details</Button></Col></Row>
      <Table dataSource={contactPeople} columns={columns} pagination={false} />

      <div>
          <InsightsSection title="Insights" tag="PREFERRED" companyInsights={lead.company_insights} peopleInsights={lead.people_insights} />          

        </div>      


    </Drawer>
  );
};

export default CompanyDetailsDrawer;
